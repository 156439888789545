.mainLoginManagement {
    width: 368px;
    margin: 0 auto;
    padding-top: 12vh;
   
  }
  .loginContainer{
    height:100vh;
      background-repeat: no-repeat;
      background-position: center 0;
      background-size: 100%;
  }
  
